/***** IMPORTS *****/
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../Handler/Handler';
import { isError } from '../../../Handler/actions/actions';

const ApplyMembershipStyles = styled.div`
    text-align: left;
    margin: 1em;

    form {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        width: 700px;

        .formItem {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;

            label {
                letter-spacing: 1.5px;
            }

            input, textarea {
                width: 450px;
                padding: .5em;
                font-family: sans-serif;
            }

            textarea {
                height: 200px;
            }
        }

        .submitButton {
            text-align: center;
            margin-bottom: 3em;

            input {
                width: 200px;
                padding: .5em;
                border-radius: 5px;
                border: solid 1px #aaa;

                &:hover {
                    box-shadow: 2px 2px 5px #ccc;
                }

                &:active {
                    box-shadow: 1px 1px 2px #bbb;
                }
            }
        }
    }
`;


/***** INTERFACES *****/
interface IApplyMembershipProps {
    test?: boolean,
}


/***** COMPONENT-FUNCTION *****/
const ApplyMembership = ({test}: IApplyMembershipProps) => {

    /*** Context ***/
    const context = useContext(AppContext);
    const {actions} = context || {};
    const {setShowLoader, setMessage} = actions || {};

    /*** Functions ***/
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        event.persist();

        const formData = new FormData(event.target);
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone');
        const message = formData.get('message');

        const completeMessage = `
<strong>Interesse for medlemsskap:</strong>
<p>Borettslag/sameie: ${name}.</p>
<p>Epost: ${email}.</p>
${phone ? `<p>Telefon: ${phone}.</p>` : ''}
<br>
${message ? `<p>Beskjed: ${message}</p>` : ''}
`;

        //'http://localhost:5000/strandkantenbydel/us-central1/sendmail/email'

        setShowLoader(true);

        let error = '';
        const response = await fetch('https://us-central1-strandkantenbydel.cloudfunctions.net/sendmail/email', {   
            method: 'POST',
            mode: 'cors',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                test: test,
                contact: {
                    from: email, 
                    message: completeMessage,
                }
            }),
        }).then((response: any) => response.json())
        .catch((thisError) => error = thisError);

        if(isError(response) || error) {
            setMessage('Feil ved sending. Prøv igjen...');
            console.log('ERROR: ', error || response?.message);
            setShowLoader(false);
            return;
        }

        setShowLoader(false);
        setMessage('Melding sendt');
        event.target.reset();
    }
    
    /*** Return-statement ***/
    return(
        <ApplyMembershipStyles>
            <form onSubmit={handleSubmit}>

                <div className='formItem'>
                    <label htmlFor='name'>Borettslag/sameie:</label>
                    <input 
                        type='text'
                        name='name'
                        id='name'
                        required
                        placeholder='Navn på Borettslag/sameie' 
                    />
                </div>

                <div className='formItem'>
                    <label htmlFor='email'>E-post:</label>
                    <input 
                        type='email'
                        name='email'
                        id='email'
                        required
                        placeholder='E-post' 
                    />
                </div>

                <div className='formItem'>
                    <label htmlFor='phone'>Telefon (valgfritt):</label>
                    <input 
                        type='text'
                        name='phone'
                        id='phone'
                        placeholder='Telefonnummer' 
                    />
                </div>

                <div className='formItem'>
                    <label htmlFor='message'>Beskjed (valgfritt):</label>
                    <textarea
                        name='message'
                        id='message'
                        placeholder='Skriv inn beskjed her...'
                    />
                </div>

                <div className='submitButton'>
                    <input type='submit' value='Send melding' />
                </div>
            </form>
        </ApplyMembershipStyles>
    );
}


/***** EXPORTS *****/
export default ApplyMembership;
