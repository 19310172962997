/***** IMPORTS *****/
import React, {useState, useEffect, useContext} from 'react';
import {articlesObj} from '../../../Handler/FireBase/firebaseHandler';
import {IArticle} from '../../../interfaces/IGeneral';
import { AppContext } from '../../../Handler/Handler';


/***** CONTEXT *****/
export const AdminContext = React.createContext<any | null>(null);


/***** INTERFACES *****/
interface IAdminProviderProps {
    children: any,
};


/***** COMPONENT-FUNCTION *****/
const AdminProvider = (props: IAdminProviderProps) => {

    /*** Context ***/
    const context = useContext(AppContext);
    const {pages} = context?.state;
    const {setPages} = context?.actions;

    /*** State ***/
    const [allArticles, setAllArticles] = useState<IArticle[]>([]);

    //Make state-object.
    const state = {
        allArticles,
        allPages: pages,
    };

    //Make actions-object.
    const actions = {
        setAllArticles,
        setAllPages: setPages,
    };

    
    /*** Effects ***/

    //Get all articles and set them to state.
    useEffect(() => {
        articlesObj.read(true).then((thisArticles: IArticle[]) => setAllArticles(thisArticles));
    }, []);


    /*** Return-statement ***/
    return(
        <AdminContext.Provider value={{
            state, actions

        }} >

            {props.children}
            
        </AdminContext.Provider>
    );
}


/***** EXPORTS *****/
export default AdminProvider;
