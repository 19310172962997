/***** IMPORTS *****/
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../Handler/Handler';


/***** INTERFACES *****/
interface IShowLoaderProps {};


/***** COMPONENT-FUNCTION *****/
const ShowLoader = () => {

    /*** Context ***/
    const context = useContext(AppContext);
    const {showLoader} = context.state;
    const {setShowLoader} = context.actions;


    /*** Effects ***/

    //If showLoader is false or undefined, set showLoader to true.
    useEffect(() => {
        if(!showLoader) {
            setShowLoader(true);
        }

    //eslint-disable-next-line
    }, [showLoader]);


    //Cleanup. When compnent demounts, set showLoader to false.
    useEffect(() => {
        
        return () => setShowLoader(false);
    //eslint-disable-next-line
    }, []);
    

    /*** Return-statement ***/
    return null;
}


/***** EXPORTS *****/
export default ShowLoader;
