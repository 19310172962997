/***** IMPORTS *****/
import { ISettings } from "../interfaces/IState";


/***** DEFAULT STATE-OBJECTS *****/
export const defaultSettings: ISettings = {
    
    //Site-settings
    site: {
        siteTitle: 'Strandkanten Bydel SA',
        footer: 'Strandkanten Bydel © 2019',
    },

    //Theme-values
    theme: {
        primaryColor: '#246599',
        primaryText: '#ffffff',
        secondaryColor: '#2d6075',
        secondaryText: '#ffffff',
    },
}
