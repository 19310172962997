/***** IMPORTS *****/
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import Provider from './Handler/Handler';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';


/***** APP-RENDERING *****/
ReactDOM.render(
        <Provider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
, document.getElementById('root'));


/***** SERVICE-WORKER *****/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
