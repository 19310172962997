/***** IMPORTS *****/
import React from 'react';
import Test from './Test/Test';
import {Route, Switch} from 'react-router-dom';
import Main from './components/Main/Main';
import PublicWrapper from './components/Main/PublicWrapper/PublicWrapper';
import ArticleList from './components/News/ArticleList/ArticleList';
import PublicSite from './components/PublicSite';



/***** COMPONENT-FUNCTION *****/
const App: React.FC = () => {

    /*** Variables ***/
    const test = true;


  /*** Return-statement ***/
  return (<>
    {test && 
      <Route 
        path='/test/'
        exact
        component={Test}
      />
    }

    <Main>
      <Switch>
        <Route 
          path='/'
          exact
          render={() =>
            <PublicWrapper>
              <ArticleList />
            </PublicWrapper>
          }
        />

        <Route 
          path='/:section/' 
          render={() => <>
            <PublicSite />
          </>} 
        />

      </Switch>
    </Main>

  </>);
}


/***** EXPORTS *****/
export default App;
