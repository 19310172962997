/***** IMPORTS *****/
import React, {useEffect, useContext} from 'react';
import {checkLogin} from '../../../Handler/actions/authActions';
import {AppContext} from '../../../Handler/Handler';
import AdminWrapper from './AdminWrapper';
import styled from 'styled-components';
import {Link} from 'react-router-dom';


/***** STYLES *****/
const AuthorizeStyle = styled.div`
    margin-top: 2em;
    text-align: center;
    font-size: 1.3em;

    input[type='checkbox'] {
        font-size: 2em;
        width: 30px;
    }
    
    a {
        text-decoration: underline;
        font-size: 1.3em;
    }
`;


/***** INTERFACES *****/
interface IAuthorizeProps {
    children: any,
};


/***** COMPONENT-FUNCTION *****/
const Authorize = ({children}: IAuthorizeProps) => {

    /*** Context ***/
    const context = useContext(AppContext);
    const {isLoggedIn} = context.state;
    const {setIsLoggedIn, setShowLoader} = context.actions;



    /*** Effects ***/
    //Check-login
    useEffect(() => {
        setShowLoader(true);
        checkLogin(setIsLoggedIn).then((response) => {
            if(!response) console.log('Not logged in...')//history.push('/login/');
        });

    //eslint-disable-next-line
    }, []);

    //Set showLoader if children-prop is loaded or not.
    useEffect(() => {
        if(children) {
            setShowLoader(false);
        }
    //eslint-disable-next-line
    }, [children]);
    

    /*** Return-statement ***/
    return isLoggedIn
        ?   <AdminWrapper>
                {children}
            </AdminWrapper>
            
        :   <AuthorizeStyle className='unauthorized'>
                <p>Du er ikke logget inn.</p> 
                <Link to='/login/'>Logg inn her!</Link>
            </AuthorizeStyle>
}


/***** EXPORTS *****/
export default Authorize;
